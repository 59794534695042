import { get } from 'lodash'
import { ShortStates, sourcesStatesAreas } from '@/config/states-areas'
import { Localizer } from '@/utils/Localizer'
import { options as marketingOptions } from '@/config/marketing-options'
import { getSortedFields } from '@/config/fields-sorting-for-admin'
import { PropertyTypes } from '@/config/property-types'
import { Areas } from '@/config/states-areas'


// Dates

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const formatDate = (d) => {  // d - Date() object
  const monthName = d.getMonth() + 1
  return `${monthName}/${d.getDate()}/${d.getFullYear()}`
}

const formatPeriod = (p, smallestUnits = 'days') => {
  let result = ''

  const pMinutes = p / 6e4
  const pHours = pMinutes / 60
  const pDays = pHours / 24

  if (smallestUnits === 'days') {
    const days = Math.floor(pDays)
    result = `${days} day${days > 1 ? 's' : ''}`
  } else if (smallestUnits === 'hours') {
    const days = Math.floor(pDays)
    const hours = Math.floor(pHours - days * 24)
    result = days > 0 ? `${days} day${days !== 1 ? 's' : ''}` : ''
    result += hours > 0 || !days ? `${result ? ' ' : ''}${hours} hour${hours !== 1 ? 's' : ''}` : ''
  } else if (smallestUnits === 'minutes') {
    const hours = Math.floor(pHours)
    const minutes = Math.floor(pMinutes - hours * 60)
    result = hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''}` : ''
    result += minutes > 0 || !hours ? `${result ? ' ' : ''}${minutes} minute${minutes !== 1 ? 's' : ''}` : ''
  }

  return result
}


// Address

const formatAddress = (listingData) => {
  const city = get(listingData, 'propertyInfo.city', '')
  let state = get(listingData, 'propertyInfo.state', '')
  const street = get(listingData, 'propertyInfo.streetAddress', '')
  const unit = get(listingData, 'propertyInfo.unitNumber', '')
  const zipCode = get(listingData, 'propertyInfo.zipCode', '')

  if (!street || !city || !state || !zipCode) {
    return ''
  }

  state = ShortStates[state]

  return `${street}${unit ? ' ' + unit : ''}, ${city}, ${state} ${zipCode}`
}



// Get state title

const stateTitleByKey = (stateKey, lexp, clxp, glxp) => {
  return (glxp ? sourcesStatesAreas.glxp[stateKey]
    : (clxp ? sourcesStatesAreas.clxp[stateKey] : sourcesStatesAreas.lexp[stateKey])).title
}


// Emails

const buildAdminMessage = (id, data) => {
  let subject
  let html

  if (id === 'newListing') {
    const propertyType = data.listingData.propertyInfo.propertyType
    const mlsArea = data.listingData.propertyInfo.area

    subject = `NEW ${mlsArea} ${propertyType} LISTING`.toUpperCase()
    subject += `: Owner's Name: `
    subject += `${data.listingData.owner.firstName} ${data.listingData.owner.lastName}`.toUpperCase()

    html = `
    <div style="font-size: 18px;">
      <p>Hello,</p>
      <p>${data.userFullName} just submitted <a href="${data.listingURL}">new listing</a>.</p>
    `
    
    const fields = getSortedFields(data.listingData)
    html += `
    <p>
      <b>ID</b>: ${data.listingId}<br />
      <b>Listing type</b>: ${propertyType}<br /><br />
    `
    for (const field of fields) {
      html += field.label ? `<b>${field.label}</b>: ${field.value || ''}<br />` : '<br />'
    }
    html += `
      </p>
    </div>
    `
  } else if (id === 'listingChangeForm') {
    subject = 'LISTING CHANGE FORM'
    html = `
      <p>Hello,</p>
      <p>${data.userFullName} just signed a "Listing Change" form.</p>
      <p><a href="${data.formURL}">Listing Change Form</a></p>
      <p><a href="${data.listingURL}">Listing Page</a></p>
    `
  } else if (id === 'underContractForm') {
    const formTitle = data.homeRent ? 'UNDER LEASE' : 'UNDER CONTRACT'
    subject = `${formTitle} FORM`
    html = `
      <p>Hello,</p>
      <p>${data.userFullName} just signed a "${formTitle}" form.</p>
      <p><a href="${data.formURL}">${formTitle} Form</a></p>
      <p><a href="${data.listingURL}">Listing Page</a></p>
    `
  } else if (id === 'contractRelease') {
    subject = 'Contract Release Uploaded'
    html = `
      <p>Hello,</p>
      <p>${data.userFullName} just uploaded a Contract Release.</p>
      <p><a href="${data.listingURL}">Listing Page</a></p>
    `
  } else if (id === 'contract') {
    subject = 'Contract Uploaded'
    html = `
      <p>Hello,</p>
      <p>${data.userFullName} just uploaded a Contract.</p>
      <p><a href="${data.listingURL}">Listing Page</a></p>
    `
  } else if (id === 'closingDisclosure') {
    subject = 'Closing Disclosure Statement Uploaded'
    html = `
      <p>Hello,</p>
      <p>${data.userFullName} just uploaded the Closing Disclosure statement.</p>
      <p><a href="${data.listingURL}">Listing Page</a></p>
    `
  } else if (id === 'photosUpdated') {
    subject = 'New Photos Received'
    html = `
      <p>Hello,</p>
      <p>${data.userFullName} just uploaded New Photos for their listing.</p>
      <p><a href="${data.listingURL}">Listing Page</a></p>
    `
  } else if (id === 'idPhotos') {
    subject = 'New Identification Photos Received'
    html = `
      <p>Hello,</p>
      <p>${data.userFullName} just uploaded New Identification Photos for their listing.</p>
      <p><a href="${data.listingURL}">Listing Page</a></p>
    `
  } else if (id === 'addMarketingOptionsForm') {
    subject = 'LISTING OPTIONS FORM'
    html = `
      <p>Hello,</p>
      <p>${data.userFullName} just signed a Listing Options Change form.</p>
      <p><a href="${data.formURL}">Listing Options Change Form</a></p>
      <p><a href="${data.listingURL}">Listing Page</a></p>
    `
  } else if (id === 'extendListingForm') {
    subject = 'LISTING EXTENSION FORM'
    html = `
      <p>Hello,</p>
      <p>${data.userFullName} just signed a Listing Extension form.</p>
      <p><a href="${data.formURL}">Listing Extension Form</a></p>
      <p><a href="${data.listingURL}">Listing Page</a></p>
    `
  } else if (id === 'withdrawListingForm') {
    subject = 'LISTING WITHDRAWAL FORM'
    html = `
      <p>Hello,</p>
      <p>${data.userFullName} just signed a Listing Withdrawal form.</p>
      <p><a href="${data.formURL}">Listing Withdrawal Form</a></p>
      <p><a href="${data.listingURL}">Listing Page</a></p>
    `
  }

  return {
    subject,
    html,
  }
}

const buildMessage = (id, data) => {
  if (id === 'registration') {
    return {
      subject: 'Thank You For Registering',
      html: `
        <p>Hello,</p>
        <p>Thank you for registering to list your property in the MLS. </p>
        <p>To list in the MLS, Realtor.com, and hundreds of other real estate sites please go to ${data.domain.name}, login into your account and click "Create a new listing".</p>
        <p>Please let us know if you have any questions by replying to this email. </p> 
        <p>
          Thank you,<br />
          Stephanie Smith<br />
          Broker of Select Premium Properties, Inc.<br />
          <a href="${data.domain.address}">${data.domain.name}</a>
        </p>
      `,
    }
  } else if (id === 'listingReceived') {
    return {
      subject: 'Your Listing Has Been Received',
      html: `
        <p>Hello,</p>
        <p>Your listing has been received. Once your listing has been entered into the MLS, a notification email will be sent on how to view your listing.</p>
        <p>If you have any questions, please email us at service@selectpremiumprop.com.</p>
        <p>
          Thank you,<br />
          Stephanie Smith<br />
          Broker of Select Premium Properties, Inc.<br />
          <a href="${data.domain.address}">${data.domain.name}</a>
        </p>
      `
    }
  } else if (id === 'activeListing') {
    return {
      subject: 'Your Listing is now Active in the MLS',
      html: `
        <p><b>This email is specific to the property "${data.address}" and the information contained only applies to this property.</b></p>
        <p>Your Listing has been Entered into the MLS.</p>
        <p>To view important listing information please log into your online account or click on the link below.</p>
        <p><a href="${data.listingUrl}">CLICK HERE</a></p>
        <p>A copy of your MLS Listing will be emailed to you shortly.</p>
        <p>
          Thank you,<br />
          Listing Department<br />
          Select Premium Properties, Inc.<br />
          <a href="${data.domain.address}">${data.domain.name}</a>
        </p>
      `
    }
  } else if (id === 'listingStatusChanged') {
    return {
      subject: 'Your Listing Status Has Changed',
      html: `
        <p>Hello!</p>
        <p>Your listings status changed to <b>${data.newStatus}</b>.</p>
        <p><a href="${data.listingUrl}">Listing page</a></p>
        <p>
          Select Premium Properties, Inc.<br />
          <a href="${data.domain.address}">${data.domain.name}</a>
        </p>
      `
    }
  } else if (id === 'paymentReminder') {
    return {
      subject: 'MLS Listing - Payment Request',
      html: `
        <p>Hello!</p>
        <p>Thank you for your MLS Listing. However, we are still in need of payment before it can be completed. For fastest service, and to pay via secured credit card, please <a href="${data.listingUrl}">CLICK HERE</a>.</p>
        <p>Or you may send a check or money order to:</p>
        <p>
          Select Premium Properties<br />
          1213 Culbreth Dr #375<br />
          Wilmington, NC 28405
        </p>
        <p>Once payment has been received your MLS Listing can be processed. Please let us know if you have any questions.</p>
        <p>
          Thank you,<br />
          Listing Department<br />
          Select Premium Properties, Inc.
        </p>
      `
    }
  }
}

const generateString = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
 }
 return result
}

const formatAdditionalOwner = (firstName, lastName) => {
  if (!firstName && !lastName) {
    return ''
  }
  return `${firstName ?? ''} ${lastName ?? ''}`.trim()
}


export {
  formatDate,
  formatPeriod,
  formatAddress,
  buildMessage,
  buildAdminMessage,
  stateTitleByKey,
  generateString,
  formatAdditionalOwner,
}